@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
div,
span,
p,
a,
del,
em,
img,
ol,
ul,
li,
fieldset,
form,
label {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    letter-spacing: 0.5px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

html,
body {
    line-height: 1;
    min-height: 100%;
    font-family: "Roboto", sans-serif;

    overflow-x: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.modal-backdrop {
    max-width: 480px;
    margin: 0 auto;
    overflow: hidden;
    right: 0;
}

b,
strong {
    font-weight: 600
}

.col-center {
    margin: 0 auto;
}

.container {
    max-width: 94vw;
}

.container-fluid {
    padding: 0 20px;
}

section {
    width: 100%;
}



img {
    max-width: 100%
}


#content-body {
    max-width: 480px;
    margin: 0 auto;
    box-shadow: 0 10px 10px #ccc;
    min-height: 100vh;
    overflow-x: hidden;
}

h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #212529
}


p,
ol,
ul,
li,
.overflow-terms i {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    color: #000000
}

.red {
    color: #18738e
}

.btn {
    border: none;
    border-radius: 100px;
}

.btn-primary {
    color: #153752;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 100px;
    background: #FFC107;
    box-shadow: 0px 4px 0px 0px #153752;
}
.btn-primary:hover{
    background:#267191;
}
.btn-primary-outline {
    background: #fff;
    border: 1px solid #18738e;
    color: #18738e;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 20px;
}

.btn-bottom-fixed {
    position: fixed;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 40px;
    max-width: 480px;
}

.btn-bottom-fixed .btn {
    width: 300px;
    min-height: 52px;
    line-height: 32px;
    max-width: 100%;
}

.btn-next {
    position: relative;
    width: 300px;
    max-width: 100%;
}

.btn-previous {
    display: flex;
    column-gap: 10px;
    color: #767676;
}

.btn-previous + .btn-next,
.btn-simpan {
    width: 200px;
    max-width: 100%;
}

.btn-next img {
    position: absolute;
    right: 20px;
    top: 14px;
}

label {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
}

.form-group {
    margin-bottom: 20px;
}

.form-control {
    height: 55px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border: 1px solid #E4E4E4;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #BEBEBE;
    opacity: 1;
    /* Firefox */
}

.form-control:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #BEBEBE;
}

.form-control::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #BEBEBE;
}

.form-control:focus,
.form-control:active {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #ccc;
    background: #fafafa
}

.input-group-text {
    background: #fff;
    width: 60px;
    justify-content: center;
    align-items: center;
    border: 1px solid #E4E4E4;
}

.nav-top-inside {
    display: flex;
    column-gap: 20px;
    margin-bottom: 0;
    padding: 20px 10px;
    background: #fff;
    position: fixed;
    top: 0;
    max-width: 480px;
    z-index: 99;
    width: 100%;
    align-items: center;
}

.nav-top-inside a {
    text-decoration: none;
    font-size: 28px;
    color: #212528;
}

/*HOME*/
.home-top-banner {
    background: url(../img/bg-banner.jpg) no-repeat;
    color: #fff;
    min-height: 260px;
    background-size: 100% 100%;
    background-position: 0 0;
    position: relative;
    padding: 60px 0;
    z-index: 1;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
}

.nav-top {
    width: 100%;
    justify-content: space-between;
    padding: 10px 20px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    max-width: 480px;
    margin: 0 auto;
    left: 0;
    justify-content: end;
}

.nav-top img {
    height: 50px;
}

.content-banner {
    width: 100%;
    margin-top: 20px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.content-banner img {
    margin: 0px auto 30px;
}

.content-banner .text-img {
    max-width: 120px;
}

.content-banner h2 {
    color: #000;
}

.content-banner h2 span {
    background: #FFC107;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 100px;
    margin: 10px 0;
    color: #CC000E;
}

.slide-banner {
    padding-bottom: 30px;
    position: relative;
    z-index: 2;
}

.slick-banner-symposium {
    margin: -50px 0 20px;
}

.slick-banner-symposium .slick-slide {
    border-radius: 5px;
    padding-bottom: 20px;
}


.caption-symposium {
    padding: 0px 0px 10px 0px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 15px 20px 0px rgba(33, 37, 41, 0.05);
    overflow:hidden;
}

.caption-symposium h2 {
    font-family: "Manrope", sans-serif;
    font-weight: 700;
}

.slick-banner-symposium a {
    text-decoration: none;
}

.slick-banner-symposium a iframe {
    border-radius: 10px 10px 0 0;
}

.slick-dots li button:before {
    width: 5px;
    height: 5px;
    padding: 0;
    line-height: normal;
    font-size: 8px;
    opacity: 1;
    color: #FFE6E6
}

.slick-dots li.slick-active button:before {
    color: #e30010;
    opacity: 1
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}


.our-menu ul {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 20px 0;
    list-style: none;
    column-gap: 10px;
}


.our-menu ul li {
    width: 50%;

}

.our-menu ul li a {
    display: flex;
    width: 100%;
    padding: 15px 10px;
    column-gap: 20px;
    border-bottom: 3px solid #FFC107;
    align-items: center;
    text-decoration: none;
    border-radius: 10px;
    border-radius: 10px 10px 0 0;
    background: #FFF;
    box-shadow: 0px 15px 20px 0px rgba(33, 37, 41, 0.05);
}

.our-menu ul li a:hover {

    border-bottom: 3px solid #18738e;
}

.our-menu ul li a img {
    height: 64px;
}

.register-klaim {
    position: relative;
    margin-top: -60px;
    z-index: 9;
}

.banner-register {
    border-radius: 10px;
    background: #eee;
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 15px;
    overflow: hidden;
    background-size: 100% 100%;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    text-align: center;
}

.klaim-berhasil .banner-register {
    border-radius: 10px;

    /* background: url(../img/bg-banner-yellow.png) no-repeat; */

    display: flex;
    align-items: center;
    position: relative;
    padding: 25px 10px 20px 20px;
    overflow: hidden;
    padding-left: 37%;
    background-size: 100% 100%;
}

/* .register-klaim.klaim-berhasil .container-fluid:before {
    content: '';
    position: absolute;
    display: block;
    background: url(../img/circle-bg2.png) no-repeat;
    width: 100%;
    height: 100%;
    background-position: left top;
    z-index: 1;
    background-size: 90.5%;
} */


.register-klaim .container-fluid {
    position: relative
}

/* .register-klaim .container-fluid:before {
    content: '';
    position: absolute;
    display: block;
    background: url(../img/circle-bg.png) no-repeat;
    width: 100%;
    height: 100%;
    background-position: left top;
    z-index: 1;
    background-size: 90.5%;
}
 */

.banner-register > * {
    position: relative;
    z-index: 2
}

.banner-register img {
    margin-right: 10px;
    height: 126px;
}

/*PENDAFTARAN*/
.pendaftaran {
    min-height: 100vh;
    position: relative;
    padding-top: 75px;
    padding-bottom: 80px;
}

.form-pendaftaran form {
    margin: 20px 0 0;
}

.form-pendaftaran .form-group,
.form-pendaftaran p {
    margin: 0 20px;
}

.form-pendaftaran .btn {
    margin: 30px 0 0;
    border-radius: 0;
    min-height: 55px;
    position: fixed;
    max-width: 480px;
    bottom: 0;
    line-height: 35px;
    background: var(--gradient, linear-gradient(253deg, #1C86B7 33.81%, #153752 111.59%));
    color: #fff;
    box-shadow: none;
}

.form-pendaftaran .form-group p {
    margin: 10px 0
}

.input-group-text i {
    font-size: 20px;
}

span.mandatory {
    color: #e50111;
}

/*MODAL TERMS*/
#ModalTerms .modal-dialog {
    max-width: 440px;
    margin: 0 auto;
    top: 10%;
}

#ModalTerms img {
    height: 50px;
    margin-bottom: 20px;
}

#ModalTerms h2 {
    margin-bottom: 20px;
}

.overflow-terms {
    max-height: 350px;
    overflow: hidden;
    overflow-y: auto;
}

.hide {
    display: none;
}

.show {
    display: block;
}


.overflow-terms p,
.overflow-terms ul,
.overflow-terms ol {
    margin-bottom: 20px;
}

.overflow-terms ul li,
.overflow-terms ol li {
    list-style: decimal;
    list-style-position: inside;
    margin-bottom: 20px;
}

.overflow-terms ul li ul li {
    margin-bottom: 20px;
    list-style: circle;
    list-style-position: inside;
}

.overflow-terms ul li::marker {}

.overflow-terms ul li ul {
    margin: 20px 10px;
}

/*BOTTOM SHEET*/


/*MODAL KLAIM GAGAL*/
#ModalKlaimGagal .modal-dialog {
    max-width: 340px;
    margin: 0 auto;
    top: 20%;
    text-align: center;
}

.modal div img {
    margin: 20px 0;
}

.modal h2 {
    color: #18738e;
}

.modal .btn {
    min-width: 160px;
    margin-bottom: 20px;
}

/*PENDAFTARAN BERHASIL*/
.banner-top-daftar {
  background-size: 100% 100%;
  background-position: 0 0;
  position: relative;
  padding: 0;
  z-index: 1;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  margin-top: 4rem;
}

.banner-top-daftar h3 {
  color: #1D4A68;
}

.illustration-top img {
    width: 40%;
}

.logo-top {
    text-align: right;
    margin: 0 20px;
}
.desc-text-box {
    max-width: 400px;
    margin: 40px auto;
    text-align: center;
}

.desc-text-box h2 {
    color: #18738e;
}

.desc-text-box p {
    color: #767676;
}


.btn-new {
   background: linear-gradient(90deg, #142C43 0%, #1C4866 100%);
   color: #ffff;
   border-radius: 15px;
   font-size: 12px;
   padding: 12px 0px;
   width: 180px;
}

/*FOOTER*/
footer {
    background: linear-gradient(270deg, #1C4967 -2.19%, #142C43 59.59%);
    padding: 20px 0 0;
    color: #fff;
    margin-top: 30px;
}

footer p {
    color: #FFF;
    font-family:  "Manrope", sans-serif;
    /* Small Text/Regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

footer p img {
    margin-right: 10px;
}

footer p a {
    color: #fff;
    text-decoration: none
}

.copyright {
    font-family:  "Manrope", sans-serif;
    margin-top: 20px;
    padding: 10px;
    color: #153752;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    background: #FFC107;
}




.form-wizard {
    background: #fff;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 30px;
    border-radius: 55px;
    outline-offset: -40px;
}

.form-wizard ::-moz-selection {
    background: #ea2803;
    color: #fff;
    text-shadow: none;
}

.form-wizard ::selection {
    background: #ea2803;
    color: #fff;
    text-shadow: none;
}

.form-wizard .box-questions {
    display: none;
    text-align: left;
    border: 0px !important
}


.form-wizard .progress-bar {
    background-color: #ea2803;
}

.form-wizard-steps {
    margin: auto;
    position: relative;
    background: #FFE6E6;
    max-width: 440px;
    border-radius: 100px;
}

.form-wizard-step {
    box-sizing: border-box;
    display: inline-block;
    color: #88206D;
    position: absolute !important;
    right: 0;
    top: -30px;
    display: none;
    width: 100%;
}

.form-wizard-step.active {
    color: #fff;
    display: flex;
    background: #fff;
    justify-content: space-between;
}

.form-wizard-step.activated {
    background: #fff;
    color: #fff;
    display: flex;
    font-size: 14px;
}

.form-wizard-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFE6E6;
}

.form-wizard-progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    background: #FFC107;
    border-radius: 100px;
}

.form-wizard-buttons {
    position: fixed;
    bottom: 40px;
    max-width: 440px;
    margin: 0 auto;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
}


.form-wizard h2 {
    padding: 20px;
    margin: 0 auto;
    border-radius: 100px;
    border-radius: 0px 0px 20px 20px;
    background: #FFF;
    box-shadow: 0px 15px 20px 0px rgba(33, 37, 41, 0.05);
}

.form-wizard-step p:first-child {
    position: relative;
    top: -8px;
    background: #fff;
}

.form-wizard-step p span {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #18738e;
    margin-right: 10px;
}



.choose-option input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    height: 24px;
    width: 24px;
    border: 0.15em solid #D9D9D9;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.choose-option input[type="radio"]:checked {

    border: 0.15em solid #FFC107;
}

.choose-option input[type="radio"]::before {
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #FFC107;
    /* Windows High Contrast Mode */
    background-color: #fff;
}

.choose-option input[type="radio"]:checked::before {
    transform: scale(1);
}



.choose-option .form-control {
    display: flex;
    column-gap: 10px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
}

.choose-option {
    max-width: 440px;
    margin: 40px auto;
}

.modal-brosur .modal-dialog {
    max-width: 480px;
    margin: 0 auto;
}

.modal-brosur .modal-body {
    padding: 0;
}

.modal-brosur .modal-body .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #fff;
    opacity: 1;
    z-index: 999;
}

.modal-brosur .modal-body img {
    margin: 0;
}

.modal-brosur .modal-dialog {
    background: #fff;
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.modal-brosur .modal-content {
    border: none;
}

#ModalSimpan.modal .btn {
    min-width: 43%;
    min-height: 43px;
}

#ModalSimpan.modal {
    text-align: center;
    top: 20%;
}

.scan-qr-box {
    background: url(../img/scan-qr-box.svg) no-repeat;
    background-size: 100%;
    min-height: 100vh;
    background-position: 0 -100px;
}

.scan-qr {
    position: fixed;
    bottom: 10%;
    z-index: 999;
    color: #fff;
    max-width: 100%;
    width: 480px;
    text-align: center;
}

.scan-qr p {
    color: #fff;
}

a.btn-scan {
    border-radius: 70px;
    background: #FFE6E6;
    width: 70px;
    height: 70px;
    display: block;
    margin: 20px auto;
    border: 10px solid #fff;
}

.img-product-detail img {
    max-width: 200px;
    margin: 0 auto;
    display: block;
}

.img-product-detail {
    padding: 20px 0;
    border-bottom: 1px solid #E4E4E4;
    padding-top: 75px;
}

.detail-info h2 {
    color: #18738e;
}

.detail-info {
    padding: 20px 0
}

span.badge-yellow {
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #FFC107;
    background: #FFF8EE;
    font-size: 12px;
}

.badge-list {
    margin: 15px 0;
    display: flex;
    column-gap: 10px;
}

button.accordion-button {
    border-bottom: none !important;
    box-shadow: none !important;
    background: transparent !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.accordion-item:last-of-type .accordion-button.collapsed {}

.accordion-button:not(.collapsed) {
    padding-bottom: 0;
}

.accordion-item {
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, .125) !important;
    border-radius: 10px !important;
    padding: 0;
}

.accordion-button:not(.collapsed),
.accordion-button:not(.collapsed):before {
    color: #18738e;
}

.accordion-body,
.accordion-button {
    padding: 15px;
}

.accordion-button:after {
    /* background: url(../img/ic-down-black.svg) no-repeat */
}

.accordion-button:not(.collapsed):after {
    /* background: url(../img/ic-down-red.svg) no-repeat; */
    transform: none;
}

.detail-info table td {
    font-size: 12px;
    padding: 5px 5px;
}

.detail-info table td:last-child {
    text-align: right;
}

.detail-info table {
    width: 100%;
}

.detail-info table tr:first-child td {
    font-weight: 600;
    text-align: left;
    padding: 10px 5px;
}

.list-symposium a {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 15px 20px 0px rgba(33, 37, 41, 0.05);
    display: block;
    margin: 20px;
}

.slick-banner-symposium.list-symposium {
    margin-top: 0;
}

.list-symposium a span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    color: #18738e;
    margin-top: 15px;
}

.slick-similar-product a img {
    height: 140px;
    margin: 20px auto;
}

.slick-similar-product a .caption-product {
    border-top: 1px solid #E4E4E4;
}

.slick-similar-product a {
    border: 1px solid #E4E4E4;
    border-radius: 10px;
    text-decoration: none;
    color: #000;
    display: block;
}

.slick-similar-product .caption-product {
    padding: 10px;
}

.slick-similar-product .caption-product h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-height: 50px;
}

.slick-similar-product .slick-slide {
    padding: 10px 5px;
}

.slick-similar-product .caption-product span {
    color: #18738e;
    /* Large Text/600 */
    font-family:  "Manrope", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.product-list {
    padding-top: 75px;
}

.list-jadwal-symposium {
    padding-top: 75px
}

section.detail-symposium {
    padding-top: 75px;
}

section.detail-symposium .caption-symposium {
    box-shadow: none;
}

.detail-symposium .caption-symposium h2 {
    color: #18738e;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.desc-symposium {
    border-top: 1px solid #E4E4E4;
    margin-top: 20px;
    padding-top: 20px;
}

.detail-symposium .caption-symposium .desc-symposium h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #212529;
}

.desc-symposium ol {
    list-style-position: inside;
}

.desc-symposium img {
    margin-top: 20px;
}

.slick-banner-product .slick-slide {
    margin: 0 10px;
}

ul#TabProducts li {
    width: 33.333%;
}

ul#TabProducts li button {
    width: 100%;

    border: none;
    border-bottom: 3px solid #FFC107 !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #212529;
    padding: 16px;
    border-radius: 10px 10px 0 0;
}

ul#TabProducts li button.active {
    background: #18738e2b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #18738e;
}

.slick-banner-product ul.slick-dots {
    bottom: -25px;
}

section.product-morinaga {
    margin-top: 15px;
}

.product-morinaga .slick-similar-product a {
    width: calc(50% - 25px);
    margin: 0 10px 20px;
}

.product-morinaga .slick-similar-product {
    display: flex;
    flex-wrap: wrap;
}

.product-morinaga .slick-similar-product a img {
    display: block;
    margin: 0 auto;
}

ul.tabs-nav li a {
    display: inline-block;
    padding: 10px 50px;
    border-radius: 30px;
    border: 1px solid #E4E4E4;
    background: #fff;
    color: #767676;
    text-decoration: none;
    font-weight: 600;
}

ul.tabs-nav {
    list-style: none;
    display: inline-block;
    padding: 20px 10px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    overflow-x: auto;
}

ul.tabs-nav li {
    display: inline-block;
    padding-right: 10px;
}

ul.tabs-nav li.tab-active a {
    color: #18738e;
    border: 1px solid #18738e;
}

select.form-control,
.form-select {
    /* background-image: url(../img/ic-select.svg) !Important; */
    background-repeat: no-repeat !important;
    background-position: 95% center !Important;
    background-size: 24px;
}

.bottom-box {
    padding: 20px 10px 38px;
    background: #fff;
    position: fixed;
    bottom: -100%;
    width: 480px;
    border-radius: 20px 20px 0 0;
    box-shadow: 10px -4px 10px #ccc;
    z-index: 9999;
    transition: 0.5s all;
    max-width: 100%;
}

.bottom-box.active {
    bottom: 0;
}

.bottom-box .choose-option {
    margin-top: 10px;
    margin-bottom: 20px;
}

.bottom-box .btn {
    width: 100%;
    position: fixed;
    left: 0;
    border-radius: 0;
    width: 480px !important;
    max-width: 100% !important;
    margin: 0 auto !important;
    right: 0;
    left: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #0000004f;
    border-radius: 5px;
    background-size: 21px;
}

.service-list .shadow-light {
    height: 155px;
    width: 155px;
    left: 50%;
    top: 50%;

    transform: translate(-50%, -50%);
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, #1C4967 0%, rgba(20, 44, 67, 0.4) 100%);
}
.service-list .img-container {
    position: relative;
    height: 90px;
    margin: 20px;
}
.service-list img {
position: relative;
}

.service-list {
    overflow: hidden;
    position: relative;
    text-align: left;
    align-items: center;
    padding: 10px 0;
    background-color:#142C43;
    color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.service-list * {
    color: #FFFFFF;
}

.service-list + .service-list {
    border: none;
}

h3.title-yellow {
    color: #F2BA43;
    text-shadow: 0px 3px 0px #153653;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #153653;
}

footer .lucide {
    width: 16px !important;
    margin-right: 10px;
}

h6 {
    color: #000000;

    /* Base Text/Bold */
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}


.slider-logo img {
  width: 100px;
  height: 60px;
  animation: scroll 60s linear infinite;
  max-width: none;
  object-fit: contain;
  margin: 0 20px;
  mix-blend-mode: luminosity;
}

.slide-logo-track {
  width: 100%;
  display: flex;
  /* gap: 3em; */
  overflow: hidden;
}

.slider-logo {
  margin: 0 -30px;
  margin-top: 40px;
}

@keyframes scroll {
  0% {transform: translateX(0);}
  100% {transform: translatex(-1000%)}
}

@media(max-width:460px) {

    .banner-register {
        padding: 20px 10px
    }

    .our-menu ul li a img {
        height: 40px
    }

    h4,
    .btn-primary {
        font-size: 12px;
    }

    .btn-primary {
        /*padding: 5px 10px*/
        padding: 7px 15px 5px 15px
    }

    .banner-register img {
        max-width: 140px;
        height: 90px;
    }

    .nav-top img {
        height: 55px;
    }

    .home-top-banner {
        min-height: 220px;
    }

    .form-wizard-steps {
        margin: 0 20px;
    }

    .form-wizard .box-questions .choose-option {
        padding: 0 20px;
    }

    .form-wizard-buttons {
        padding: 0 10px;
    }

    .btn-previous + .btn-next,
    .btn-simpan {
        width: 150px;
    }

    .btn-previous {
        font-size: 14px;
    }

    .form-wizard h2 {
        font-size: 18px;
    }

    .choose-option .form-control {
        font-size: 14px;
    }

    .content-banner h2 {
        font-size: 15px;
        margin: 10px 0 0;
    }

    .content-banner {
        padding: 0;
    }

    #ModalTerms .modal-body {
        padding: 0 20px;
    }

    .caption-symposium h2 {
        font-size: 14px;
    }

    .klaim-berhasil .banner-register {
        padding-left: 34%;
    }

    .klaim-berhasil .banner-register .btn {
        padding: 8px 12px;
        margin-top: 4px;
    }

    .content-banner {
        margin-top: 0
    }

    .caption-symposium p {
        font-size: 11px;
        margin-right: 10px;
    }

    .caption-symposium p img {
        width: 16px;
    }

    .caption-symposium {
        padding: 15px 10px;
    }

    footer p,
    .copyright {
        font-size: 11px;
    }

    footer .d-flex.row .col-md-7,
    footer .d-flex.row .col-md-5 {
        width: 50%;
        padding-right: 0;
    }

    footer .d-flex.row .col-md-7 p,
    footer .d-flex.row .col-md-5 p {
        word-break: break-word;
    }

    footer .container-fluid {
        padding: 0 10px;
    }

    button.accordion-button {
        font-size: 12px;
    }

    .detail-info h2 {
        font-size: 18px;
    }

    span.badge-yellow {
        font-size: 11px;
    }

    h2 {
        font-size: 16px;
    }

    .nav-top-inside h2 {
        margin-bottom: 0;
    }

    .list-symposium a span {
        font-size: 12px;
    }



    .slick-similar-product .caption-product h2 {
        font-size: 12px;
        min-height: 50px;
    }

    .slick-similar-product .caption-product span {
        font-size: 12px;
    }

    #ModalTerms .modal-dialog {
        margin: 0 10px
    }

    /* width */
    .tabs-nav::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    .tabs-nav::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    .tabs-nav::-webkit-scrollbar-thumb {
        background: transparent;
    }

    /* Handle on hover */
    .tabs-nav::-webkit-scrollbar-thumb:hover {
        background: transparent;
    }
}


.invalid-feedback{
    font-size: 10px;
}