@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?fy02eq');
  src:  url('../fonts/icomoon.eot?fy02eq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?fy02eq') format('truetype'),
    url('../fonts/icomoon.woff?fy02eq') format('woff'),
    url('../fonts/icomoon.svg?fy02eq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-narrow-left:before {
  content: "\e900";
  color: #767676;
}
.icon-ad_units:before {
  content: "\e901";
  color: #767676;
}
.icon-award-02:before {
  content: "\e902";
  color: #767676;
}
.icon-domain:before {
  content: "\e903";
  color: #767676;
}
.icon-captive_portal:before {
  content: "\e904";
  color: #767676;
}
.icon-garage_home:before {
  content: "\e905";
  color: #767676;
}
.icon-user-01:before {
  content: "\e906";
  color: #767676;
}
.icon-chevron_left:before {
  content: "\e907";
}
.icon-x-close:before {
  content: "\e908";
}
